import React from 'react'
// import Layout from '../components/Layout'

const NotFoundPage = () => (
  <div>
    <h1>NOT FOUND</h1>
    <p>No page found at this URL</p>
  </div>
)

export default NotFoundPage
